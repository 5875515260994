import React, { useEffect, useState, useCallback } from 'react';
import { useStyles, TableControl, InputContainer, InputSelect } from './styles';
import { TableCell, IconButton, Tooltip } from '@material-ui/core';
import { useProfessional } from 'hook/professional/index';
import Layout from 'shared/component/Layout';
import DefaultTable from 'shared/component/DefaultTable';
import TabPanel from 'shared/component/tabPanel';
import SearchInputDebounce from 'shared/component/forms/SearchInputDebounce';
import { AvatarNameComponent } from 'shared/component/AvatarNameComponent';
import CustomSelect from 'shared/component/forms/CustomSelect';
import '../../shared/fixedtab.css';
import moment from 'moment';
import StatusChip from 'shared/component/StatusChip';
import { useHistory } from 'react-router-dom';
import { Today } from '@material-ui/icons';
import debounce from 'lodash/debounce'; // Certifique-se de instalar o lodash

export default function UniversalSearchPage() {
  const classes = useStyles();
  const { push } = useHistory();

  const permissions = {
    read: 'professional/r',
    create: 'professional/c',
    delete: 'professional/d',
    update: 'professional/u',
    export: 'professional/export',
  };

  const columns = () => {
    return [
      { id: 'id', label: 'Cód. Profissional', minWidth: 100 },
      {
        id: 'name',
        label: 'Nome',
        minWidth: 100,
        render: (rowData, index) => (
          <AvatarNameComponent key={index} rowData={rowData} />
        ),
      },
      { id: 'cpf', label: 'CPF', minWidth: 100 },
      { id: 'group.name', label: 'Campo de atuação', minWidth: 100 },
      { id: 'company.name', label: 'Companhia', minWidth: 100 },
      {
        id: 'unit',
        label: 'Hospital',
        minWidth: 100,
        render: (rowData, index) => (
          <SpecialtiesNames key={index} specialties={rowData?.units} />
        ),
      },
      {
        id: 'created_at',
        label: 'Data de criação',
        minWidth: 10,
        render: (rowData) => (
          <TableCell>
            {moment(rowData.created_at).format('DD/MM/yyyy')}
          </TableCell>
        ),
      },
      {
        id: 'status',
        label: 'Status',
        minWidth: 10,
        render: (rowData, index) => (
          <StatusChip key={index} rowData={rowData} />
        ),
      },
      {
        id: 'schedule',
        label: 'Agenda',
        minWidth: 10,
        render: (rowData, index) => (
          <TableCell key={index}>
            <Tooltip title="Ir para agenda do profissional">
              <IconButton
                onClick={() => push(`/agenda/profissional/${rowData.id}`)}
              >
                <Today color="primary" />
              </IconButton>
            </Tooltip>
          </TableCell>
        ),
      },
    ];
  };

  const SpecialtiesNames = ({ specialties }) => {
    if (!specialties || !specialties.length > 0)
      return <TableCell>...</TableCell>;
    const concatedNames = specialties.map((item) => item?.name).join(' , ');
    return <TableCell>{concatedNames}</TableCell>;
  };

  const {
    data: dataProfessionals,
    loading: loadingProfessionals,
    total: totalProfessionals,
    deleteLoading,
    getProfessionals,
    deleteProfessional,
  } = useProfessional();

  const [filterProfessionals, setFilterProfessionals] = useState({
    page: 1,
    search: '',
    per_page: 10,
    status: 'all',
  });

  const [profissionalStatus, setProfissionalStatus] = useState({
    label: 'Todos',
    value: 'all',
  });

  // Função debounce para limitar chamadas frequentes
  const debouncedGetProfessionals = useCallback(
    debounce((filters) => {
      getProfessionals(filters);
    }, 300), // 300ms de espera antes de disparar a requisição
    []
  );

  const handleChangeProfessionalStatus = (val) => {
    setProfissionalStatus(val);
    setFilterProfessionals((oldState) => ({
      ...oldState,
      status: val?.value,
      page: 1,
    }));
  };

  useEffect(() => {
    debouncedGetProfessionals(filterProfessionals);
  }, [filterProfessionals, debouncedGetProfessionals]);

  const handleChangePage = (page) => {
    setFilterProfessionals((prev) => ({ ...prev, page }));
  };

  const handleSearch = (search) => {
    if (typeof search === 'string') {
      setFilterProfessionals((prev) => ({ ...prev, page: 1, search }));
    }
  };

  const typeProfessionalStatus = [
    { label: 'Todos', value: 'all' },
    { label: 'Ativos', value: 'active' },
    { label: 'Inativos', value: 'deactive' },
    { label: 'Em moderação', value: 'moderating' },
    { label: 'Deletados', value: 'deleted' },
  ];

  useEffect(() => {
    if (dataProfessionals.length < 1) {
      setFilterProfessionals((prev) => ({
        ...prev,
        page: 1,
      }));
    }
  }, []);

  const handleDeleteProfessional = (id_professional) => {
    dataProfessionals.length > 1
      ? deleteProfessional({ id_professional, params: filterProfessionals })
      : deleteProfessional({
          id_professional,
          params: {
            ...filterProfessionals,
            page: 1,
          },
        });
  };

  return (
    <Layout title="Busca universal de Profissionais" showToday backArrow>
      <div style={{ margin: '0 0px 0 30px', backgroundColor: '#EDEDED' }}>
        <div
          data-cy="div_professional_filters"
          className={classes.inputContainer}
        >
          <InputContainer>
            <SearchInputDebounce
              value={filterProfessionals.search}
              className={classes.inputWidth}
              placeholder="Busque por nome ou CPF"
              onChange={handleSearch}
              style={{
                flex: 1,
                alignItems: 'center',
                height: 48,
                width: '100%',
              }}
            />
          </InputContainer>

          <InputSelect>
            <CustomSelect
              isProf={false}
              name="status"
              loading={false}
              placeholder="Selecione o status do profissional"
              handleChange={handleChangeProfessionalStatus}
              className={classes.withoutBorder}
              value={profissionalStatus}
              options={typeProfessionalStatus}
            />
          </InputSelect>
        </div>

        <div className={classes.root}>
          <TabPanel>
            <TableControl data-cy="dataTable">
              <DefaultTable
                permissions={permissions}
                dataList={dataProfessionals}
                pageByProps={filterProfessionals.page}
                columns={columns()}
                loading={loadingProfessionals}
                totalList={totalProfessionals}
                redirectTo="/cadastros/profissional/editar"
                perPage={filterProfessionals?.perPage}
                handleChangePage={handleChangePage}
                deleteLoading={deleteLoading}
                onDeleteRequest={handleDeleteProfessional}
                showExportButton={false}
                useTimeOut={false}
              />
            </TableControl>
          </TabPanel>
        </div>
      </div>
    </Layout>
  );
}
