import React, { useRef, useState, useEffect } from 'react';
import { useStyles, TableControl, InputSelectHospital } from './styles';
import { Divider, Icon, IconButton, Tooltip } from '@material-ui/core';
import { toPrice } from 'utils/converters';
import Layout from 'shared/component/Layout';
import TabPanel from 'shared/component/tabPanel';
import ContainerCollapse from './components/ContainerCollapse';
import CollapseTable from 'shared/component/CollapseTable';
import moment from 'moment';
import useReport from 'hook/report';
import ActionsButtons from './components/ActionsButtons';
import ProfileRow from './components/profileRow';
import { icons } from 'asset';
import FilterShiftsModal from './components/FilterModal';
import ChipFilter from './components/ChipFilter';
import ProfessionalModal from './components/ProfessionalModal';
import useUser from 'hook/user';
import useUnit from 'hook/unit';
import usePersistedState from 'hook/usePersistedState';
import CustomSelect from 'shared/component/forms/CustomSelect';
import { ModalResponseAlert } from 'page/Schedule/WeekSchedule/components/ModalResponseAlert';
import HasPermission from 'utils/checkPermission';

import '../../shared/fixedtab.css';
import CustomSelectProfessionals from 'shared/component/forms/CustomSelectProfessionals';
import { useAuth } from 'hook/auth';

export default function ReportShiftsPage() {
  const { userLogged } = useAuth();
  const classes = useStyles();
  const { getUserStandardInformation, user } = useUser();
  const { data } = user;
  const { units, getUnits } = useUnit();
  const pageRef = useRef(1);
  const [datesInitialized, setDatesInitialized] = useState(false);

  const permissions = {
    read: 'report/r',
    export: 'report/export',
  };
  const {
    reports,
    loading,
    totalProfessional,
    reportsWithDetails,
    getReports,
    getReportsWithDetails,
    getProfessionalByHospital,
    getExcelReports,
  } = useReport();

  const { list: listReports, loading: loadingReports, pagination } = reports;
  const { listWithDetails, paginationWithDetails } = reportsWithDetails;
  const [userDetailModal, setUserDetailModal] = useState(false);
  const [reportsFilter, setReportsFilter] = React.useState({
    page: 1,
    perPage: 10,
    search: '',
    unitId: '',
    userId: '',
    typeGroup: '',
    atSight: false,
    validated: 0,
  });
  const {
    page,
    perPage,
    search,
    unitId,
    userId,
    typeGroup,
    atSight,
    validated,
  } = reportsFilter;

  const [userShiftsFilter, setUserShiftsFilter] = React.useState({
    pageUserShifts: 1,
    perPageUserShifts: 4,
  });
  const { pageUserShifts, perPageUserShifts } = userShiftsFilter;
  const [id, setId] = useState('');
  const [seletedRow, setSelectedRow] = useState(0);
  const [filterModal, setFilterModal] = useState(false);
  const [openByProps, setOpenByProps] = useState(false);
  const [filterType, setFilterType] = useState('universal');
  const [selectedScaleList, setSelectedScaleList] = useState([]);
  const [openModalSendNotification, setOpenModalSendNotification] =
    React.useState(false);
  const handleCloseModal = () => {
    setOpenModalSendNotification(false);
  };
  const [week, setWeek] = useState({
    dateStart: moment().format('YYYY-MM-DD'),
    dateEnd: moment().format('YYYY-MM-DD'),
  });
  const { dateStart, dateEnd } = week;

  const detailsRow = (id) => {
    setId(id);
  };
  const openFilterModal = () => {
    setFilterModal(!filterModal);
  };

  useEffect(() => {
    if (datesInitialized) {
      getReports({
        page,
        perPage,
        search,
        unitId,
        dateStart,
        dateEnd,
        scaleIds: selectedScaleList,
        filter: filterType,
        userId,
        typeGroup,
        atSight,
        validated,
        company: userLogged.company.id,
      });
    }
  }, [
    unitId,
    reportsFilter,
    selectedScaleList,
    week,
    filterType,
    userId,
    typeGroup,
  ]);

  React.useEffect(() => {
    getReportsWithDetails({
      page: pageUserShifts,
      perPage: perPageUserShifts,
      unitId,
      dateStart,
      dateEnd,
      userId: id,
      filter: filterType,
      scaleId: selectedScaleList,
    });
  }, [pageUserShifts, id, openByProps, unitId]);
  const handleChangePage = (page) => {
    setReportsFilter({ ...reportsFilter, page });
  };

  const newPageDetails = () => {
    setUserShiftsFilter({
      ...userShiftsFilter,
      pageUserShifts: pageUserShifts + 1,
    });
  };
  const prevPageDetails = () => {
    setUserShiftsFilter({
      ...userShiftsFilter,
      pageUserShifts: pageUserShifts - 1,
    });
  };

  const componentMinimizable = () => (
    <ContainerCollapse
      rowData={listWithDetails}
      loadingReports={loadingReports}
      pagination={paginationWithDetails}
      prevPage={prevPageDetails}
      nextPage={newPageDetails}
    />
  );

  const handleDateChange = ({ date_start, date_end }) => {
    setDatesInitialized(true); // Marca as datas como inicializadas
    setWeek({
      ...week,
      dateStart: date_start,
      dateEnd: date_end,
    });
  };

  const removeFilter = (removedFilter, itemLength) => {
    if (itemLength > 3) {
      const newScaleList =
        selectedScaleList.length > 0 &&
        selectedScaleList.filter((scale) => scale.id !== removedFilter);
      setSelectedScaleList(newScaleList);
    }
  };

  const selectedArrays = [...selectedScaleList];

  const userDetails = (id) => {
    setUserDetailModal(!userDetailModal);
    getUserStandardInformation({
      id,
    });
  };
  const resetPageDetails = () => {
    setUserShiftsFilter({ ...userShiftsFilter, pageUserShifts: 1 });
  };
  const [filterUnit, filterStateUnit] = useState({
    unitPage: 1,
    unitPerPage: 30,
    unitSearch: '',
  });
  const { unitPage, unitPerPage, unitSearch } = filterUnit;
  const [filterProfessional, setFilterProfessional] = useState({
    professionalPage: 1,
    professionalPerPage: 100,
    professionalSearch: '',
  });
  const { professionalPage, professionalPerPage } = filterProfessional;
  const [unitSelected, setUnitSelected] = React.useState();
  const [professionalSelected, setProfessionalSelected] = useState([]);
  const { loadingUnits, dataUnits, totalUnits } = units;

  const searchUnit = (unitValue) => {
    filterStateUnit({ ...filterUnit, unitSearch: unitValue });
  };

  const unitPagination = () => {
    const nextPage = pageRef.current + 1;
    const totalPage = Math.ceil(totalUnits / unitPerPage);
    if (pageRef.current < totalPage) {
      filterStateUnit({ ...filterUnit, unitPage: nextPage });

      pageRef.current = nextPage;
    }
  };

  const topUnitPagination = () => {
    const previousPage = pageRef.current - 1;
    if (pageRef.current > 1) {
      filterStateUnit({ ...filterUnit, unitPage: previousPage });

      pageRef.current = previousPage;
    }
  };

  const professionalPagination = () => {
    const totalPageProfessional = Math.ceil(
      totalProfessional / professionalPerPage
    );
    professionalPage < totalPageProfessional &&
      setFilterProfessional({
        ...filterProfessional,
        professionalPage: professionalPage + 1,
      });
  };

  const topProfessionalPagination = () => {
    professionalPage > 1 &&
      setFilterProfessional({
        ...filterProfessional,
        professionalPage: professionalPage - 1,
      });
  };
  const formatUnitsOptions = dataUnits.map((company) => ({
    label: company.name,
    value: company.id,
  }));

  const typeReport = [
    { label: 'Agrupadas', value: 1 },
    { label: 'Desagrupadas', value: 2 },
  ];

  const [reportStatus, setReporStatus] = usePersistedState(
    'plantaoextra@statusProfessional',
    {
      label: 'Agrupadas',
      value: 1,
    }
  );

  useEffect(() => {
    getUnits(unitPage, unitPerPage, unitSearch);
  }, [unitPage, unitSearch]);

  useEffect(() => {
    if (datesInitialized) {
      getProfessionalByHospital(
        unitId,
        professionalPage,
        professionalPerPage,
        search
      );
    }
  }, [unitId, professionalPage]);

  const handleChangeUnit = (val) => {
    setSelectedScaleList([]);
    setUnitSelected(val);
    setReportsFilter({
      ...reportsFilter,
      unitId: val?.value,
      search: '',
      page: 1,
    });
    getProfessionalByHospital(
      unitId,
      professionalPage,
      professionalPerPage,
      search
    );
  };

  const handleChangeTypeReport = (val) => {
    setReporStatus(val);
    setReportsFilter({
      ...reportsFilter,
      typeGroup: val?.value,
    });
  };

  const handleChangeProfessional = (val) => {
    setProfessionalSelected(val);
    setReportsFilter({
      ...reportsFilter,
      userId: val?.value,
    });
  };

  const openExcel = (userId) => {
    if (unitId == '') {
      setOpenModalSendNotification(true);
    } else {
      if (listReports.length < 1) {
        setOpenModalSendNotification(true);
      } else {
        getExcelReports({
          data: {
            date_start: dateStart,
            date_end: dateEnd,
            unit_id: unitId !== null ? unitId : undefined,
            company_id: unitId === null ? userLogged.company.id : undefined,
            user_id: userId,
            scaleIds: selectedScaleList,
            type_group: reportStatus.value,
            at_sight: atSight,
            validated,
          },
        });
      }
    }
  };

  return (
    <Layout
      title="Plantões"
      backArrow
      calendarRange
      handleDateChange={handleDateChange}
    >
      <div id="fixed-tab">
        <Divider style={{ marginBottom: 24, paddingTop: 2, marginRight: 20 }} />
        <div className={classes.inputContainer}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <InputSelectHospital data-cy="inputSelectHospital">
              <CustomSelect
                isProf={false}
                name="unit"
                loading={loadingUnits}
                placeholder="Selecione o hospital"
                handleChange={handleChangeUnit}
                value={unitSelected}
                searchItem={searchUnit}
                pagination={unitPagination}
                toTopPagination={topUnitPagination}
                options={[
                  { label: 'Todas', value: null },
                  ...formatUnitsOptions,
                ]}
                isClearable
              />
            </InputSelectHospital>
            <div style={{ marginLeft: 30 }}>
              <InputSelectHospital data-cy="inputSelectProfissional">
                <CustomSelectProfessionals
                  name="report_type"
                  id="InputProfissional"
                  placeholder="Selecione o profissional"
                  handleChange={handleChangeProfessional}
                  customStyle={{
                    control: () => ({
                      display: 'flex',
                      width: '100%',
                      minHeight: 39,
                      borderRadius: 4,
                      fontSize: 12,
                    }),
                    menu: (props) => ({ ...props, zIndex: 10 }),
                  }}
                  pagination={professionalPagination}
                  toTopPagination={topProfessionalPagination}
                  loading={loading}
                  value={professionalSelected}
                  unit_id={unitSelected?.value}
                  isClearable
                />
              </InputSelectHospital>
            </div>
            <div style={{ marginLeft: 30 }}>
              <InputSelectHospital data-cy="inputSelectAgrupar">
                <CustomSelect
                  isProf={false}
                  name="report_type"
                  loading={false}
                  placeholder="Selecione o tipo de relatório"
                  handleChange={handleChangeTypeReport}
                  value={reportStatus}
                  options={typeReport}
                />
              </InputSelectHospital>
            </div>

            <div style={{ marginLeft: 30 }}>
              {HasPermission(permissions.export) ? (
                <Tooltip title="EXPORTAR EXCEL" placement="bottom" arrow>
                  <IconButton
                    onClick={() => openExcel(professionalSelected?.value)}
                    style={{
                      padding: 0,
                      marginTop: 0,
                    }}
                  >
                    <Icon style={{ fontSize: 30, color: '#0F83AD' }}>
                      article_outlined
                    </Icon>
                  </IconButton>
                </Tooltip>
              ) : (
                <Tooltip
                  title="Você não tem permissão"
                  placement="bottom"
                  arrow
                >
                  <IconButton
                    style={{
                      padding: 0,
                      marginTop: 0,
                    }}
                  >
                    <Icon style={{ fontSize: 30, color: '#9D9DA9' }}>
                      article_outlined
                    </Icon>
                  </IconButton>
                </Tooltip>
              )}
              {unitId == '' ? (
                <ModalResponseAlert
                  messageTitleAlert="Selecione um hospital!"
                  openSendFinish={openModalSendNotification}
                  handleCloseSendFinish={handleCloseModal}
                  error
                />
              ) : (
                <ModalResponseAlert
                  messageTitleAlert="Não há plantões para exportar!"
                  openSendFinish={openModalSendNotification}
                  handleCloseSendFinish={handleCloseModal}
                  error
                />
              )}
            </div>
          </div>
          <FilterShiftsModal
            openFilter={filterModal}
            handleClose={openFilterModal}
            filterModal={filterModal}
            setSelectedScaleList={setSelectedScaleList}
            selectedScaleList={selectedScaleList}
            setFilterModal={setFilterModal}
            setFilterType={setFilterType}
            unitId={unitId}
            dateStart={dateStart}
          />
        </div>

        <div
          style={{
            display: 'flex',
            maxWidth: '80%',
            marginTop: 11,
          }}
        >
          <IconButton
            onClick={openFilterModal}
            data-cy="iconButtonFiltroEscalas"
          >
            <img src={icons.filter} style={{ width: 26 }} />
          </IconButton>
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              listStyle: 'none',
              marginBottom: 10,
            }}
          >
            {selectedArrays &&
              selectedArrays.length > 0 &&
              selectedArrays.map((filter) => (
                <ChipFilter
                  key={filter.id}
                  name={filter.name}
                  id={filter.id}
                  itemLength={Object.keys(filter).length}
                  onClick={removeFilter}
                />
              ))}
          </div>
        </div>
      </div>
      <div className={classes.root}>
        <TabPanel>
          <TableControl>
            <CollapseTable
              dataList={listReports}
              columns={[
                {
                  id: 'name',
                  label: 'Profissional',
                  minWidth: 170,
                  render: (rowData) => (
                    <ProfileRow
                      id={rowData.id}
                      name={rowData.name}
                      userImage={rowData.image}
                      specialty={rowData.group.name}
                      onClick={userDetails}
                    />
                  ),
                },
                {
                  id: 'total_on_duty',
                  label: 'N. de plantões',
                  minWidth: 100,
                },
                {
                  id: 'total_price',
                  label: 'Valor Total',
                  minWidth: 100,
                  format: (price) =>
                    `R$ ${
                      userLogged?.company?.id !== 59 ? toPrice(price) : '-'
                    }`,
                },
                {
                  id: 'actions',
                  minWidth: 100,
                  render: (rowData) => (
                    <div style={{ display: 'flex', gap: '10px' }}>
                      {HasPermission(permissions.export) ? (
                        <Tooltip
                          title="EXPORTAR EXCEL"
                          placement="bottom"
                          arrow
                        >
                          <IconButton
                            onClick={() => openExcel(rowData.id)}
                            style={{
                              padding: 0,
                              marginTop: 0,
                            }}
                          >
                            <Icon style={{ fontSize: 30, color: '#0F83AD' }}>
                              article_outlined
                            </Icon>
                          </IconButton>
                        </Tooltip>
                      ) : (
                        <Tooltip
                          title="Você não tem permissão"
                          placement="bottom"
                          arrow
                        >
                          <IconButton
                            style={{
                              padding: 0,
                              marginTop: 0,
                            }}
                          >
                            <Icon style={{ fontSize: 30, color: '#9D9DA9' }}>
                              article_outlined
                            </Icon>
                          </IconButton>
                        </Tooltip>
                      )}

                      <ActionsButtons
                        handleDetailsPage={resetPageDetails}
                        setOpenByProps={setOpenByProps}
                        seletedRow={seletedRow}
                        openByProps={openByProps}
                        row={rowData}
                        detailRequest={detailsRow}
                        setSelectedRow={setSelectedRow}
                      />
                    </div>
                  ),
                },
              ]}
              loading={loadingReports}
              totalList={pagination?.total_items}
              redirectTo="/cadastros/administradores/cadastrar"
              perPage={perPage}
              handleChangePage={handleChangePage}
              showActionsButtons={false}
              componentMinimizable={componentMinimizable}
              seletedRow={seletedRow}
              open={false}
              openByProps={openByProps}
            />
          </TableControl>
        </TabPanel>
      </div>
      <ProfessionalModal
        openDetails={userDetailModal}
        handleCloseDetails={userDetails}
        user={data}
      />
    </Layout>
  );
}
