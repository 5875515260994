import React, { useState, useContext } from 'react';
import {
  Button,
  CircularProgress,
  Modal,
  TextareaAutosize,
  Checkbox,
  FormControlLabel,
} from '@material-ui/core';
import { ScheduleWeekPageContext } from 'page/Schedule/WeekSchedule/index';
import { useAgendaValidation } from 'hook/agenda/validation';
import moment from 'moment'; // Importando moment

export function ModalAcceptValidation({
  open,
  close,
  title,
  setStatusError = () => {},
  setResponseMessage = () => {},
  setTitleMessage = () => {},
  setOpenAlertMessage = () => {},
}) {
  const { loading, putAgendaValidation } = useAgendaValidation();

  const {
    agendaDetails,
    selectedUnit,
    setAgendaDetailsData = () => {},
    handleGetAgenda = () => {},
  } = useContext(ScheduleWeekPageContext);

  const [validated_description, setValidatedDescription] = useState('');
  const [startTime, setStartTime] = useState(''); // Formato: "YYYY-MM-DDTHH:mm"
  const [endTime, setEndTime] = useState(''); // Formato: "YYYY-MM-DDTHH:mm"
  const [showTimeFields, setShowTimeFields] = useState(false);
  const [error, setError] = useState({
    description: false,
    startTime: false,
    endTime: false,
  });

  const handleSubmit = () => {
    const newError = {
      description: validated_description === '',
      startTime: showTimeFields && startTime === '',
      endTime: showTimeFields && endTime === '',
    };

    if (newError.description || newError.startTime || newError.endTime) {
      setError(newError);
      return;
    }

    // Validação: data de fim deve ser posterior à data de início
    if (showTimeFields && new Date(endTime) <= new Date(startTime)) {
      setError({ ...error, endTime: true });
      setResponseMessage(
        'A data de saída deve ser posterior à data de entrada!'
      );
      setStatusError(true);
      return;
    }

    setError({ description: false, startTime: false, endTime: false });

    const formData = {
      id: agendaDetails?.id,
      validated_status: 2,
      validated_description,
      ...(showTimeFields && {
        user_date_start: moment(startTime).format('YYYY-MM-DD HH:mm:ss'), // Formata para "2024-03-31 20:40:00"
      }),
      ...(showTimeFields && {
        user_date_end: moment(endTime).format('YYYY-MM-DD HH:mm:ss'), // Formata para "2024-03-31 20:40:00"
      }),
      unit: { id: selectedUnit?.id },
      scale: { id: agendaDetails?.scale.id },
    };

    putAgendaValidation({ agenda_id: agendaDetails?.id }, formData)
      .then((data) => {
        close();
        setValidatedDescription('');
        setStartTime('');
        setEndTime('');
        setShowTimeFields(false);
        setAgendaDetailsData(data);
        setTitleMessage('Plantão validado com sucesso!');
        setResponseMessage('');
        setOpenAlertMessage(true);
        setTimeout(() => {
          handleGetAgenda();
        }, 300);
      })
      .catch(() => {
        setTimeout(() => {
          setTitleMessage('Falha ao validar plantão!');
          setResponseMessage('');
          setStatusError(true);
        }, 300);
      });
  };

  return (
    <Modal
      open={open}
      onClose={close}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <div
        style={{
          width: '360px',
          height: showTimeFields ? '460px' : '360px',
          padding: '2rem',
          background: '#FFFFFF',
          borderRadius: '10px',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <p>{title}</p>
        <TextareaAutosize
          style={{
            width: '100%',
            height: showTimeFields ? '80px' : '128px',
            borderRadius: '4px',
            marginTop: '16px',
            border: '1px solid #A2A5A8',
            padding: '1rem',
          }}
          onChange={(e) => setValidatedDescription(e.target.value)}
          rowsMax={showTimeFields ? 4 : 8}
          placeholder="Observação"
        />
        {error.description && (
          <span style={{ fontSize: 12, marginTop: 5, color: 'red' }}>
            Obrigatório!
          </span>
        )}
        <FormControlLabel
          control={
            <Checkbox
              checked={showTimeFields}
              onChange={(e) => setShowTimeFields(e.target.checked)}
              color="primary"
            />
          }
          label="Informar horário do profissional"
          style={{ marginTop: '16px' }}
        />
        {showTimeFields && (
          <>
            <div style={{ marginTop: '16px' }}>
              <label style={{ fontSize: '14px' }}>
                Data e Horário de Entrada
              </label>
              <input
                type="datetime-local"
                value={startTime}
                onChange={(e) => setStartTime(e.target.value)}
                style={{
                  width: '100%',
                  height: '40px',
                  borderRadius: '4px',
                  border: '1px solid #A2A5A8',
                  padding: '0.5rem',
                  marginTop: '8px',
                }}
              />
              {error.startTime && (
                <span style={{ fontSize: 12, color: 'red' }}>Obrigatório!</span>
              )}
            </div>
            <div style={{ marginTop: '16px' }}>
              <label style={{ fontSize: '14px' }}>
                Data e Horário de Saída
              </label>
              <input
                type="datetime-local"
                value={endTime}
                onChange={(e) => setEndTime(e.target.value)}
                style={{
                  width: '100%',
                  height: '40px',
                  borderRadius: '4px',
                  border: '1px solid #A2A5A8',
                  padding: '0.5rem',
                  marginTop: '8px',
                }}
              />
              {error.endTime && (
                <span style={{ fontSize: 12, color: 'red' }}>
                  Obrigatório e deve ser posterior à entrada!
                </span>
              )}
            </div>
          </>
        )}
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Button
            style={{
              background: '#0F83AD',
              width: '136px',
              height: '32px',
              fontSize: '12px',
              borderRadius: '4px',
              color: '#ffffff',
              textTransform: 'unset',
              marginTop: '32px',
            }}
            onClick={handleSubmit}
          >
            {loading ? (
              <CircularProgress size={20} style={{ color: 'white' }} />
            ) : (
              'Confirmar'
            )}
          </Button>
        </div>
      </div>
    </Modal>
  );
}
